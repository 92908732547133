import Cookie from '../../../dev/js/components/cookie.js';

if (window.location.hostname.indexOf('trustindex.io') === 0) {
	window.location.href = window.location.href.replace(window.location.hostname, 'www.' + window.location.hostname);
}

const urlQueryString = new URLSearchParams(window.location.search).get('url') || null;
const agentId = new URLSearchParams(window.location.search).get('a') || null;
const campaignId = new URLSearchParams(window.location.search).get('c') || null;
const cookieLifeTime = 2200;
var params = new URLSearchParams(window.location.search).get('p') || null;

if (agentId) {
	const cleanAgentId = agentId ? agentId.trim().substring(0, 10) : null;
	const cleanCampaignId = campaignId ? campaignId.trim().substring(0, 20) : null;

	if (cleanAgentId) {
		Cookie.set('agent_id', cleanAgentId, cookieLifeTime);
		sessionStorage.setItem('agent_id', cleanAgentId);
	}

	if (cleanCampaignId) {
		Cookie.set('campaign_id', cleanCampaignId, cookieLifeTime);
		sessionStorage.setItem('campaign_id', cleanCampaignId);
	}
}

if (params) {
	if (decodeURIComponent(params) !== params) {
		params = decodeURIComponent(params);
	}

	var oldParams = JSON.parse(Cookie.get('params') || '[]');
	var newParams = JSON.parse(params || '[]');
	const mergedParams = Object.assign({}, oldParams, newParams);

	const paramsStr = JSON.stringify(mergedParams);
	Cookie.set('params', paramsStr, cookieLifeTime);
	sessionStorage.setItem('params', paramsStr);
}

if (urlQueryString) {
	window.location.replace(`https://${window.location.hostname}${urlQueryString}`);
}

var urlPath = 'https://' + window.location.hostname + window.location.pathname;
window.history.pushState({ 'html': document.documentElement.outerHTML, 'pageTitle': document.title }, '', urlPath);
