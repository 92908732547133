export default class Cookie {
	constructor() { }

	static set(cname, cvalue, hours, crossDomain = false) {
		const d = new Date();
		d.setTime(d.getTime() + (hours * 60 * 60 * 1000));
		let expires = "expires=" + d.toUTCString();
		let cookie = cname + "=" + cvalue + ";" + expires + ";path=/;";
		if (crossDomain) {
			cookie += "domain=" + (window.location.hostname.match(/[^\.]*\.[^.]*$/)[0]) + ";";
		}
		document.cookie = cookie;
	}

	static get(cname) {
		let name = cname + "=";
		let ca = document.cookie.split(';');
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}


	static arrayPush(cname, cvalue, hours) {
		let cookie = Cookie.get(cname);
		cookie = cookie === '' ? [] : JSON.parse(cookie);

		if (!cookie.includes(cvalue)) {
			cookie.push(cvalue);
		}

		Cookie.set(cname, JSON.stringify(cookie), hours);
	}

	static delete(cname, crossDomain = false) {
		const d = new Date(0);
		let expires = "expires=" + d.toUTCString();
		let cookie = cname + "=;" + expires + ";path=/;";
		if (crossDomain) {
			cookie += "domain=" + (window.location.hostname.match(/[^\.]*\.[^.]*$/)[0]) + ";";
		}
		document.cookie = cookie;
	}
}